import {WarningOutlined} from '@mui/icons-material';
import {Button, Paper, Theme, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {FC} from 'react';
import {FormattedMessage} from 'react-intl';
import {LandingBackground} from '../../Common';

const useStyles = makeStyles((theme: Theme) => ({
    dialogTitle: {
        padding: 16,
        textAlign: 'center',
    },
    dialogActions: {
        padding: 16,
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        paddingTop: 20,
        display: 'flex',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
    },
}));

type Props = {
    readonly handleReload: () => void;
    readonly errorMessage: string;
    readonly buttonTitle: string;
};

export const LoginErrorPage: FC<Props> = ({handleReload, errorMessage, buttonTitle}) => {
    const classes = useStyles();
    return (
        <LandingBackground>
            <Paper elevation={8} className={classes.paper}>
                <div className={classes.icon}>
                    <WarningOutlined fontSize="large" color="error" />
                </div>
                <div className={classes.dialogTitle}>
                    <Typography variant="h6">
                        <FormattedMessage id={errorMessage} />
                    </Typography>
                </div>
                <div className={classes.dialogActions}>
                    <Button variant="outlined" color="primary" onClick={handleReload}>
                        <FormattedMessage id={buttonTitle} />
                    </Button>
                </div>
            </Paper>
        </LandingBackground>
    );
};
