import {useMutation, useQuery} from '@apollo/client';
import {LoadingDialog, useMatches} from '@eon.cz/apollo13-frontend-common';
import {
    Komodita,
    Mutation,
    PasswordQuality,
    PristupyMutationSetActiveArgs,
    Query,
    UcetTyp,
    UctyMutationAddFcmRegistrationTokenArgs,
    UctyMutationEnableBiometricsArgs,
    UctyMutationRevokeFcmRegistrationTokenArgs,
} from '@eon.cz/apollo13-graphql';
import {useRouter} from 'next/router';
import {FC, useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Lang} from '../Lang';
import {Store} from '../Store';
import {useAuthAction} from '../modules/Auth/actions/AuthAction';
import {ChooseAccess} from '../modules/Auth/components/ChooseAccess';
import {ChooseKomodita} from '../modules/Auth/components/ChooseKomodita';
import {LoginErrorPage} from '../modules/Auth/components/LoginErrorPage';
import {LoginPasswordStrengthDialog} from '../modules/Auth/components/LoginPasswordStrengthDialog';
import {MobileKomoditaInfoDialog} from '../modules/Auth/components/MobileKomoditaInfo';
import {setChangeLoginStatus} from '../modules/Auth/reducers/AuthReducer';
import {getErrorMessage, isLogged, isLoggedInRestrictedMode, pathToSkip, removeCookie, storeLoggedUserForDebug} from '../modules/Auth/service/AuthService';
import {PageRoute} from '../modules/Common';
import {LandingBackground} from '../modules/Common/components';
import {KomoditaContext} from '../modules/Common/contexts/KomoditaContext';
import {AdminContext, PageContext} from '../modules/Common/contexts/PageContext';
import {PermissionStatus} from '../modules/Common/model/MobilePropsModel';
import {sendDataToRN} from '../modules/Common/services/CommonService';
import {getFromLS, getKomodity, getPovoleneTypyPriloh} from '../modules/Common/services/PageService';
import {ProfileService} from '../modules/Profile/service';
import {UcetMutations, UcetQueries} from '../modules/Ucet';
import {apolloClient} from './withApolloProvider';

type Props = {
    readonly children: any;
};

/**
 * Wrapper komponenta pro přihlášeného uživatele
 */
export const WithProfile: FC<Props> = ({children}) => {
    const matches = useMatches();

    const {
        push,
        query: {error},
        reload: handleReload,
        pathname,
    } = useRouter();
    const {isLogouting, isLogin, reload, passwordQuality, refetchStatus} = useSelector((state: Store) => state.auth);
    const {admin} = useSelector((state: Store) => state.common);
    const {mobileNotification, notificationGranted, publicKey, biometryAvailable, deviceId, isMobile, userId, firstLogin} = getFromLS('mobile');

    const dispatch = useDispatch();

    const backToLogin = () => push('/login');

    const [showPasswordStrengthDialog, setShowPasswordStrengthDialog] = useState(false);

    const [loggedInRestrictedMode, setLoggedInRestrictedMode] = useState(false);

    const {komodita} = useContext(KomoditaContext);

    const {logout, fetchLoginConfig} = useAuthAction();

    const handleOnChooseKomodita = (komodita: Komodita) => () => {
        ProfileService.setKomodita(komodita);
        push(`/${komodita.toLowerCase()}`);
    };

    const handleLogout = () => logout(apolloClient);

    const handleClosePasswordStrengthDialog = () => {
        setShowPasswordStrengthDialog(false);
    };

    const needprofile = isLogged();

    const {loading, data, refetch} = useQuery<Query>(UcetQueries.gql.withProfile, {
        fetchPolicy: 'no-cache',
        skip: !needprofile,
        onCompleted: (res) => {
            storeLoggedUserForDebug(res?.me?.email);
            if (notificationGranted === PermissionStatus.GRANTED && isMobile)
                sendToken({variables: {input: {token: mobileNotification?.tokenId ?? '', deviceId: mobileNotification?.deviceId as string}}});
            if (notificationGranted !== PermissionStatus.GRANTED && isMobile && mobileNotification?.deviceId) {
                revokeToken({variables: {input: {deviceId: mobileNotification?.deviceId as string}}});
            }
            if (publicKey && biometryAvailable) {
                enableBiometrics({variables: {input: {publicKey, deviceId: deviceId as string}}});
            }
            if (userId && userId !== me?.id && isMobile && biometryAvailable) {
                sendDataToRN({saveUserId: me?.id});
            }
            if (isMobile && biometryAvailable) {
                sendDataToRN({biometricsAutoLogin: 'FALSE'});
            }
        },
        onError: () => handleLogout(),
        notifyOnNetworkStatusChange: true,
    });

    const handleRefresh = () => refetch();

    const [setActivePristup] = useMutation<Mutation, PristupyMutationSetActiveArgs>(UcetMutations.gql.setActivePristup, {
        onCompleted: (res) => {
            const skupina = res.ucty.pristupy?.setActive.skupina;
            if (skupina?.id) {
                dispatch(setChangeLoginStatus());
                handleRefresh();
            }
        },
        awaitRefetchQueries: true,
    });
    const [sendToken] = useMutation<Mutation, UctyMutationAddFcmRegistrationTokenArgs>(UcetMutations.gql.sendMobileToken);
    const [revokeToken] = useMutation<Mutation, UctyMutationRevokeFcmRegistrationTokenArgs>(UcetMutations.gql.revokeMobileToken);
    const [enableBiometrics] = useMutation<Mutation, UctyMutationEnableBiometricsArgs>(UcetMutations.gql.enableBiometrics);

    const [unsetPristup] = useMutation<Mutation>(UcetMutations.gql.unsetActivePristup, {
        onCompleted: () => handleRefresh(),
        refetchQueries: [{query: UcetQueries.gql.profileHeader}],
    });

    const me = data?.me;

    const email = me?.email;

    const pristupy = data?.ucty?.getActivePristupy;

    const adminContext = data?.adminContext;

    const povoleneTypyPriloh = data?.nastaveni?.prilohy?.povoleneTypyPriloh;

    const restrictedMode = typeof window !== 'undefined' && isLoggedInRestrictedMode();

    useEffect(() => {
        fetchLoginConfig();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (typeof window !== 'undefined' && !needprofile && !pathToSkip.includes(pathname)) {
            removeCookie();
            push({pathname: PageRoute.LOGIN.path});
        }
    }, [needprofile, pathname, push]);

    useEffect(() => {
        if (isLogin && passwordQuality === PasswordQuality.PRILIS_SLABE) {
            setShowPasswordStrengthDialog(true);
        }
    }, [passwordQuality, isLogin]);

    useEffect(() => {
        if (restrictedMode) {
            setLoggedInRestrictedMode(restrictedMode);
        }
    }, [restrictedMode]);

    useEffect(() => {
        if (reload) {
            dispatch(setChangeLoginStatus());
            handleRefresh();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload]);

    useEffect(() => {
        if (pristupy?.length === 0 && me?.typ === UcetTyp.ZADATEL && me?.skupina) {
            unsetPristup();
        }
    }, [pristupy, me, unsetPristup]);

    if (isLogouting) {
        return (
            <LandingBackground>
                <LoadingDialog open={true} description={Lang.PROFILE_LOGGING_OUT} />
            </LandingBackground>
        );
    }

    const profileError = needprofile && !loading && !me;

    if (profileError) {
        const errorMessage = Lang.PROFILE_LOAD_ERROR;
        const buttonTitle = Lang.PROFILE_RELOAD;
        return <LoginErrorPage errorMessage={errorMessage} buttonTitle={buttonTitle} handleReload={handleReload} />;
    }
    if (error) {
        const errorMessage = getErrorMessage(error as string);

        const buttonTitle = Lang.LOGIN_ZPET_NA_PRIHLASENI;
        return <LoginErrorPage errorMessage={errorMessage} buttonTitle={buttonTitle} handleReload={backToLogin} />;
    }

    const handleChooseSkupina = (idPristupu: string | undefined) => () => {
        setActivePristup({variables: {idPristupu: idPristupu as string}});
    };

    const isValidKomodita = ProfileService.getKomodita();

    if (isLogged() && me?.registrovany && showPasswordStrengthDialog) {
        return (
            <LandingBackground>
                <LoginPasswordStrengthDialog email={email} onClickNo={handleClosePasswordStrengthDialog} onClose={handleClosePasswordStrengthDialog} />
            </LandingBackground>
        );
    }

    if (pristupy?.length && !reload && !refetchStatus && !me?.pristup) {
        const isActivePristupy = pristupy?.filter((pristup) => !!pristup?.aktivni);
        if (isActivePristupy?.length === 1 && !admin) {
            const idPristupu = isActivePristupy[0]?.id;
            setActivePristup({variables: {idPristupu: idPristupu as string}});
        } else if (!me?.skupina || admin)
            return <ChooseAccess handleChooseSkupina={handleChooseSkupina} handleLogout={handleLogout} isActivePristupy={isActivePristupy} />;
    }

    if (isLogged() && !isValidKomodita && me?.registrovany && !loggedInRestrictedMode && me?.typ !== UcetTyp.ZADATEL) {
        const komodityList = getKomodity(me);
        const zadnaKomoditaKVyberu = komodityList.length === 0;
        if (komodityList.length === 1) {
            handleOnChooseKomodita(komodityList[0])();

            // Wait for redirect
            return (
                <LandingBackground>
                    <LoadingDialog open={true} description={Lang.PROFILE_LOADING_PORTAL} />
                </LandingBackground>
            );
        }

        const haveData = !zadnaKomoditaKVyberu && !loading;

        if (matches && komodityList.length === 2 && !firstLogin) {
            return <MobileKomoditaInfoDialog handleOnChooseKomodita={handleOnChooseKomodita} />;
        }
        if (matches && komodityList.length === 2 && firstLogin) {
            return handleOnChooseKomodita(komodityList[0])();
        }

        return (
            <ChooseKomodita
                handleLogout={handleLogout}
                handleOnChooseKomodita={handleOnChooseKomodita}
                haveData={haveData}
                komodityList={komodityList}
                zadnaKomoditaKVyberu={zadnaKomoditaKVyberu}
            />
        );
    }

    if (me) {
        return (
            <PageContext.Provider
                value={{
                    me,
                    pristupy,
                    komodita,
                    loggedInRestrictedMode,
                    povoleneTypyPriloh: getPovoleneTypyPriloh(povoleneTypyPriloh),
                    adminContext: adminContext as AdminContext,
                }}
            >
                <LoadingDialog open={loading} description={Lang.PROFILE_LOADING_PORTAL} />
                {children}
            </PageContext.Provider>
        );
    }

    return children;
};
