import Cookie from 'js-cookie';
import {Lang} from '../../../Lang';
import {sendDataToRN} from '../../Common/services/CommonService';

declare const window: any;

const name = 'x-access-token';
const logged = 'di-acc-to';

const RESTRICTED_MODE_KEY = 'loggedInRestrictedMode';
const USER_NAME_DEBUG_KEY = '';

/**
 * Set auth token to cookie
 *
 * @param token Token
 * @param expiresIn Expiration time in seconds, undefined for session cookie
 */
export const setCookie = (token: string, expiresIn?: number): void => {
    if (typeof window !== 'undefined') {
        window.sessionStorage.setItem('logged', 'true');
        Cookie.set(logged, Math.floor(Math.random() * 1_000_000_000).toString(), {
            expires: expiresIn ? expiresIn / 86_400 : undefined,
            secure: window.location.protocol === 'https:',
            sameSite: 'Strict',
        });
        sendDataToRN({logouting: true});
    }
    // NOTE: We set cookie to be secure only when we are on https - this is for dev computers
    Cookie.set(name, token, {expires: expiresIn ? expiresIn / 86_400 : undefined, secure: window.location.protocol === 'https:', sameSite: 'Strict'});
};

/**
 * Clear auth cookie (= logout)
 */
export const removeCookie = () => {
    if (typeof window !== 'undefined') {
        window.sessionStorage.clear();
        window.sessionStorage.removeItem(RESTRICTED_MODE_KEY);
    }
    Cookie.remove(name, {secure: window.location.protocol === 'https:'});
    Cookie.remove(logged, {secure: window.location.protocol === 'https:'});
    Cookie.remove('komodita');
};

export const getCookieFromServer = (req: any) => {
    return req?.cookies[name];
};

export const getCookieFromClient = () => {
    return !!Cookie.get(logged);
};

export const isLogged = (): boolean => {
    return !!Cookie.get(logged) && window.sessionStorage.getItem('logged') === 'true';
};

/**
 * Store flag that we are logged in restricted mode. We must use local storage because it must survive page reloads or even browser closing and opening again.
 */
export const storeLoggedInRestrictedMode = (value: boolean) => {
    if (value) {
        window.sessionStorage.setItem(RESTRICTED_MODE_KEY, Boolean(value).toString());
    } else {
        window.sessionStorage.removeItem(RESTRICTED_MODE_KEY);
    }
};

/**
 * Check whether we are logged in restricted mode
 */
export const isLoggedInRestrictedMode = (): boolean => {
    return window.sessionStorage.getItem(RESTRICTED_MODE_KEY) === 'true';
};

/**
 * Set (or clear) stored user name (email) that is used for debugging, because error handler
 * might have trouble accessing the store.
 *
 * @param userName User name, undefined to clear
 */
export const storeLoggedUserForDebug = (userName: string | undefined): void => {
    if (!window) {
        // Not in client
        return;
    }

    if (typeof userName === 'string') {
        window.localStorage.setItem(USER_NAME_DEBUG_KEY, userName);
    } else {
        window.localStorage.removeItem(USER_NAME_DEBUG_KEY);
    }
};

/**
 * Get user name. To be only used for debugging!!!
 */
export const getLoggerUserForDebug = (): string | undefined => {
    if (!window) {
        // Not in client
        return '*** SERVER ***';
    }

    return window.localStorage.getItem(USER_NAME_DEBUG_KEY);
};

export const ErrorResponse = {
    TOKEN_INACTIVITY_EXCEEDED: 'TOKEN_INACTIVITY_EXCEEDED',
    INVALID_TOKEN: 'INVALID_TOKEN',
    ACCESS_DENIED: 'ACCESS_DENIED',
    INVALID_LOGIN: 'INVALID_LOGIN',
    CUSTOM: 'CUSTOM',
} as const;

export const getErrorMessage = (error: string) => {
    const message = {
        [ErrorResponse.INVALID_TOKEN]: Lang.ERROR_INVALID_TOKEN,
        [ErrorResponse.TOKEN_INACTIVITY_EXCEEDED]: Lang.ERROR_INACTIVITY_TOKEN,
        [ErrorResponse.ACCESS_DENIED]: Lang.UCET_PRIHLASENI_ERROR_LOGIN,
        [ErrorResponse.INVALID_LOGIN]: Lang.ERROR_INVALID_LOGIN,
        [ErrorResponse.CUSTOM]: Lang.UCET_PRIHLASENI_ERROR_LOGIN,
    } as Record<string, string>;

    return message[error] ?? Lang.ERROR_REVOKED_TOKEN;
};

export const pathToSkip = [
    '/login',
    '/nastaveniHesla',
    '/_next',
    '/static',
    '/_info',
    '/potvrzeniMunicipality',
    '/potvrzeniPristupu',
    '/potvrzeniPristupuMunicipalitaDS',
    '/oauth/callback',
];
